<template>
  <div>
    <validation-observer
      ref="paymentAddForm"
      #default="{invalid}"
    >
      <b-modal
        id="add-modal"
        ref="add-modal"
        no-close-on-backdrop
        ok-title="確認"
        centered
        header-bg-variant="primary"
        ok-only
        @hidden="handleHide"
        @close="handleHide"
        @ok="handleOk"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            {{ paymentData.id ? '複製' : '新增' }}金流
          </h4>
        </template>

        <div v-if="!isLoadingBusy">
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >
            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <!-- 金流名稱 -->
                <b-form-group label-for="paymentName">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        金流名稱
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="金流名稱"
                    rules="required"
                  >
                    <b-form-input
                      id="paymentName"
                      v-model="paymentData.name"
                      autofocus
                      trim
                      placeholder="請輸入金流名稱"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 狀態 -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group label-for="state">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        狀態
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="paymentData.state"
                    :options="stateOptions"
                    :clearable="false"
                    :reduce="option => option.value"
                    placeholder="請選擇狀態"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>

                    <template v-slot:option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="mr-50 border state-dot"
                          :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                        />
                        {{ option.label }}
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div class="d-flex align-items-center">
                        <span
                          class="mr-50 border state-dot"
                          :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                        />
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- 類別 -->
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group label-for="type">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        類別
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="paymentData.type"
                    :options="typeOptions"
                    :clearable="false"
                    :reduce="option => option.value"
                    placeholder="請選擇類別"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有<em>{{ search }}</em> 的相關結果
                      </template>

                      <div v-else>
                        <small>暫無資料</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- 金流商 -->
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group
                  label="金流商"
                  label-for="payment_type"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        金流商
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <v-select
                    v-model="paymentData.third_party_id"
                    :options="adminPaymentState.paymentTypeOptions"
                    :clearable="false"
                    input-id="payment_type"
                    placeholder="請選擇要使用的金流商"
                    :reduce="option => option.id"
                    class="select-type"
                    label="name"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        沒有 <em>{{ search }}</em> 的相關結果
                      </template>
                    </template>

                    <template v-slot:option="option">
                      <div>
                        <div
                          class="font-weight-bold d-block text-nowrap mb-0"
                        >
                          {{ option.name }}
                        </div>
                        <!-- <small class="text-muted">{{ option.driver }}</small> -->
                      </div>
                    </template>

                    <template #selected-option="option">
                      <div>
                        <div
                          class="font-weight-bold d-block text-nowrap mb-0"
                        >
                          {{ option.name }}
                        </div>
                        <!-- <small class="text-muted">{{ option.driver }}</small> -->
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center justify-content-between font-weight-bold">
                  <div>
                    <feather-icon
                      icon="BoxIcon"
                      class="mr-50"
                      style="margin-top: -3px"
                    />

                    <span style="font-size: '16px'">金額設定</span>
                  </div>

                  <div
                    v-b-tooltip.hover.v-secondary
                    title="若金額設定為 0 不設限"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.infoImg"
                      width="22"
                      alt="金額設定說明"
                    />
                  </div>
                </div>

                <hr class="mt-50">
              </b-col>

              <!-- 單日限額 -->
              <b-col cols="6">
                <b-form-group
                  label-for="daily"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        單日限額
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="單日限額"
                    rules="required"
                  >
                    <b-form-input
                      id="daily"
                      v-model="paymentData.daily"
                      type="number"
                      trim
                      placeholder="請輸入單日限額"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 單月限額 -->
              <b-col cols="6">
                <b-form-group
                  label-for="monthly"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        單月限額
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="單月限額"
                    rules="required"
                  >
                    <b-form-input
                      id="monthly"
                      v-model="paymentData.monthly"
                      type="number"
                      trim
                      placeholder="請輸入單月限額"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 單筆下限 -->
              <b-col cols="6">
                <b-form-group
                  label-for="low"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        單筆下限
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="單筆下限"
                    rules="required"
                  >
                    <b-form-input
                      id="low"
                      v-model="paymentData.low"
                      type="number"
                      trim
                      placeholder="請輸入單筆下限"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 單筆上限 -->
              <b-col cols="6">
                <b-form-group
                  label-for="high"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        單筆上限
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="單筆上限"
                    rules="required"
                  >
                    <b-form-input
                      id="monthly"
                      v-model="paymentData.high"
                      type="number"
                      trim
                      placeholder="請輸入單筆上限"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div>
              <div class="d-flex align-items-center justify-content-between font-weight-bold">
                <div>
                  <feather-icon
                    icon="BoxIcon"
                    class="mr-50"
                    style="margin-top: -3px"
                  />

                  <span style="font-size: '16px'">參數設定</span>
                </div>

              </div>

              <hr class="mt-50">

              <!-- extraConfig -->
              <div
                v-for="element in paymentData.extraConfig"
                :key="`extra-config-${element.key}`"
              >
                <!-- (, index) -->
                <b-form-group
                  :label-for="element.key"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        {{ element.label }}
                        <small>( {{ element.key }} )</small>
                      </label>
                    </div>
                  </template>

                  <div v-if=" element.key === 'ExpireTime' || element.key ==='PaymentDelay'">
                    <b-row>
                      <b-col cols="6">
                        <v-select
                          v-model="element.value[0]"
                          :options="expireTimeDayOptions"
                          :clearable="false"
                          :reduce="option => option.value"
                        >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>
                      </b-col>

                      <b-col
                        cols="6"
                        class="pl-0 pr-2"
                      >
                        <v-select
                          v-model="element.value[1]"
                          :options="expireTimeHourOptions"
                          :clearable="false"
                          :reduce="option => option.value"
                        >
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              沒有<em>{{ search }}</em> 的相關結果
                            </template>

                            <div v-else>
                              <small>暫無資料</small>
                            </div>
                          </template>
                        </v-select>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </div>

              <!-- {{ expireTimeDayOptions }} {{ expireTimeHourOptions }} -->

              <div
                v-for="(element, index) in paymentData.config"
                :key="`config-${element.key}`"
              >
                <b-form-group
                  :label-for="element.key"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        {{ element.label }}
                        <small>( {{ element.key }} )</small>
                      </label>
                    </div>
                  </template>

                  <div class="d-flex text-nowrap align-items-center">
                    <b-form-input
                      :id="element.key"
                      v-model="element.value"
                      trim
                      :placeholder="`請輸入${element.label}`"
                    />

                    <div class="d-flex text-nowrap align-items-center">
                      <b-dropdown
                        variant="link"
                        no-caret
                        right
                        toggle-class="p-50"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          class="dropdown-item-area animate__animated animate__fadeIn"
                          @click="selectedEditRow(element, index)"
                        >
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                            class="dropdown-item-btn-image mr-50"
                            rounded
                          />
                          <span>編輯</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          class="dropdown-item-area animate__animated animate__fadeIn"
                          @click="removeItem(element, index)"
                        >
                          <b-img
                            :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                            class="dropdown-item-btn-image mr-50"
                            rounded
                          />
                          <span>移除</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>

            <!-- 新增參數 -->
            <div class="text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                size="sm"
                @click="addNewItem"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>新增參數</span>
              </b-button>
            </div>

            <!-- 規則設定 -->
            <payment-rule
              ref="paymentRuleEditModal"
              :config-array="paymentData.config"
              :config-extra-array="paymentData.extraConfig"
              :selected-index="selectedIndex"
              payment-rule-id="payment-rule-edit-modal"
              @edit-rule="editPaymentData"
            />
          </b-form>
        </div>

        <div
          v-else
          class="loading-area"
        >
          <b-img
            :src="$store.state.app.themeImages.loadingImg"
            rounded
            height="60"
            width="60"
          />
        </div>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              :disabled="invalid || isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
// import { ref } from '@vue/composition-api'
import {
  BModal, BForm, BFormGroup, BImg, BFormInput, BRow, BCol, VBTooltip, BButton, BSpinner, BDropdownItem,
  BDropdown,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { required } from '@validations'
import { min, max, regex } from 'vee-validate/dist/rules'
import {
  ValidationObserver, ValidationProvider, extend, // localize,
} from 'vee-validate'

// Component
import moment from 'moment'
import PaymentRule from './components/PaymentRule.vue'
import { usePaymentList, usePaymentType, usePaymentSetting } from '../usePayment'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BModal,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BDropdownItem,
    BDropdown,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    PaymentRule,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      missingFields: [],
      required,
      min,
      max,
      regex,
      isBusy: false,
      selectedIndex: null,
    }
  },
  computed: {
    adminPaymentState() {
      return this.$store.state['admin-payment']
    },
  },
  mounted() {
    extend('min', min)
    extend('max', max)
    extend('regex', regex)
  },
  methods: {
    moment,
    // (編輯)參數設定
    editPaymentData(item) {
      const resolveData = this.syncObject(this.blankSetting, item)
      if (this.selectedIndex !== null) {
        this.$set(this.paymentData.config, this.selectedIndex, resolveData)
        return
      }
      this.paymentData.config.push(resolveData)
    },

    // (觸發)編輯規則
    selectedEditRow(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.paymentRuleEditModal.getData(item) }, 200)
    },

    // (觸發)新增規則
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      this.selectedIndex = null
      this.$refs.paymentRuleEditModal.getData(resolveData)
    },

    // (觸發)移除規則
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.label} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.paymentData.config.splice(index, 1)
        }
      })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPaymentData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('add-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const requiredFields = {
        name: '金流名稱',
        third_party_id: '金流商',
        type: '類別',
        daily: '單日限額',
        monthly: '單月限額',
        high: '單筆上限',
        low: '單筆下限',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.paymentData[Object.keys(requiredFields)[i]] === null || this.paymentData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const resolveData = JSON.parse(JSON.stringify({
        ...this.paymentData,
        daily: Number(this.paymentData.daily),
        monthly: Number(this.paymentData.monthly),
        high: Number(this.paymentData.high),
        low: Number(this.paymentData.low),
      }))

      let state = true

      if (resolveData.extraConfig.length > 0) {
        resolveData.extraConfig.forEach(item => {
          if (item.key === 'ExpireTime' || item.key === 'PaymentDelay') {
            const resove = {
              ...item,
            }

            resove.value = `${item.value[0]}-${item.value[1]}`

            if (item.key === 'ExpireTime' && resove.value === '0-0') {
              this.useAlertToast(false, `${item.label}不得為空`)
              state = false
              return
            }

            resolveData.config.push(resove)
          }
        })
      }

      if (!state) return

      this.isBusy = true
      this.$refs.paymentAddForm.validate()
        .then(success => {
          if (success) {
            this.setPaymentCreate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetPaymentData()
                this.$nextTick(() => {
                  this.$bvModal.hide('add-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (開啟)新增金流商
    getData(data) {
      this.$bvModal.show('add-modal')
      if (!data) {
        this.isLoadingBusy = false
        return
      }
      const resolveData = this.syncObject(this.blankPaymentData, data)
      if (data.config && data.config.length > 0) {
        // resolveData.config = data.config.map(item => {
        //   const resolveItem = this.syncObject(this.blankSetting, item)
        //   return resolveItem
        // })

        const config = []
        const extraConfig = []

        data.config.forEach(item => {
          const sameExtraKey = this.blankPaymentData.extraConfig.some(el => el.key === item.key)
          if (sameExtraKey) {
            if (item.key === 'ExpireTime') {
              const value = item.value || '3-0'
              const splitValue = value.split('-')
              const valueArray = splitValue.map(Number)
              const resolve = {
                ...this.syncObject(this.blankSetting, item),
                value: valueArray,
              }
              extraConfig.push(resolve)
            }

            if (item.key === 'PaymentDelay') {
              const value = item.value || '0-0'
              const splitValue = value.split('-')
              const valueArray = splitValue.map(Number)
              const resolve = {
                ...this.syncObject(this.blankSetting, item),
                value: valueArray,
              }
              extraConfig.push(resolve)
            }
            return
          }
          const resolveItem = this.syncObject(this.blankSetting, item)
          config.push(resolveItem)
        })

        resolveData.config = config
        resolveData.extraConfig = extraConfig
      }
      this.paymentData = resolveData
      this.isLoadingBusy = false
    },
  },
  setup() {
    const {
      isBusy,
      isLoadingBusy,
      paymentData,
      setPaymentCreate,
      getPaymentTypeData,
      useAlertToast,
      useHttpCodeAlert,
    } = usePaymentList()

    const {
      syncObject,
      typeOptions,
      stateOptions,
    } = usePaymentSetting()

    const {
      initBusy,
      paymentType,
      blankSetting,
      getPaymentType,
    } = usePaymentType()

    const blankPaymentData = {
      id: null,
      name: null,
      state: false,
      third_party_id: null,
      type: null,
      daily: 0,
      monthly: 0,
      high: 0,
      low: 0,
      config: [],
      extraConfig: [{
        key: 'ExpireTime',
        label: '到期時間',
        value: [5, 0],
      }, {
        key: 'PaymentDelay',
        label: '回傳緩衝時間',
        value: [0, 0],
      }],
    }

    paymentData.value = JSON.parse(JSON.stringify(blankPaymentData))

    const resetPaymentData = () => {
      paymentData.value = JSON.parse(JSON.stringify(blankPaymentData))
      paymentType.value = []
    }

    const expireTimeDayOptions = []
    for (let i = 0; i <= 30; i += 1) {
      expireTimeDayOptions.push({ label: `${i}天`, value: i })
    }

    const expireTimeHourOptions = []
    for (let i = 0; i <= 23; i += 1) {
      expireTimeHourOptions.push({ label: `${i}小時`, value: i })
      if (i === 0) expireTimeHourOptions.push({ label: '0.5小時', value: 0.5 })
    }

    return {
      isBusy,
      isLoadingBusy,
      blankPaymentData,
      initBusy,
      paymentData,
      paymentType,
      blankSetting,
      // dateDefaluteConfig,
      getPaymentType,
      setPaymentCreate,
      getPaymentTypeData,
      resetPaymentData,

      syncObject,
      typeOptions,
      stateOptions,
      useAlertToast,
      useHttpCodeAlert,

      expireTimeDayOptions,
      expireTimeHourOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}
</style>
